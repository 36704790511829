import { useMemo } from 'react';
import { BsCalendarHeart, BsCalendarHeartFill } from 'react-icons/bs';
import { observer } from 'mobx-react-lite';
import { useEventsStore } from '../../store/index';
import { ButtonBase } from '@mui/material';

import styles from './style.module.scss';
import { addBookmark, removeBookmark } from '../../helpers/fetch';

const Bookmark = observer((props) => {
  const { eventId, size } = props;
  const eventsStore = useEventsStore();

  const checked = useMemo(
    () => !!eventsStore.bookmarks.find((b) => b.id == eventId),
    [eventsStore.bookmarks]
  );

  const onClickHandler = (e) => {
    e.stopPropagation();
    if (checked) {
      removeBookmark(eventId);
    } else {
      addBookmark(eventId);
    }
  };

  return (
    <ButtonBase
      className={styles.bookmark}
      title="избранное"
      onClick={onClickHandler}
    >
      {checked ? (
        <BsCalendarHeartFill size={size || 16} />
      ) : (
        <BsCalendarHeart size={size || 16} />
      )}
    </ButtonBase>
  );
});

export default Bookmark;
