import React, { useRef, useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useEventsStore } from '../../store/index';
import { useForm } from 'react-hook-form';
import {
  BsFillLockFill,
  BsFillUnlockFill,
  BsPinMapFill,
  BsFillGeoFill,
  BsGlobe2,
  BsGlobeEuropeAfrica,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { YMaps, Map } from '@pbe/react-yandex-maps';

import { ruRU } from '@mui/x-date-pickers/locales';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Autocomplete,
  TextField,
  Switch,
  Slider,
  InputAdornment,
  Input,
  ToggleButton,
  ToggleButtonGroup,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  ListItemIcon,
} from '@mui/material';

import styles from './styles.module.scss';
import IconButton from '../../ui/buttons/iconButton';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { toast } from '../../helpers/utils';
import Loader from '../../ui/components/loader';
import ImageCropper from '../../components/imageCropper';
import {
  getAllCities,
  getCategories,
  saveEventCover,
} from '../../helpers/fetch';
import { categoryIcons } from '../../helpers/caterogy';

const YANDEX_API_KEY = process.env.REACT_APP_YAPI;

const ruLocale =
  ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const formats = ['real', 'virtual'];

const CreateEvent = observer(() => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [citiesLoading, setCitiesLoading] = useState(false);

  const navigate = useNavigate();
  const eventsStore = useEventsStore();
  const [city, setCity] = useState(null);
  const [category, setCategory] = useState([]);
  const [format, setFormat] = useState(formats[0]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cover, setCover] = useState('');
  const [privacy, setPrivacy] = useState('PU');
  const [ageLimits, setAgeLimits] = useState({ min: 25, max: 75 });
  const [hasAgeLimits, setHasAgeLimits] = useState(false);
  const [peopleLimits, setPeopleLimits] = useState({ min: 1, max: 100 });
  const [hasPeopleLimits, setHasPeopleLimits] = useState(false);
  const [coords, setCoords] = useState([0, 0]);
  const [eventData, setData] = useState({
    start: moment().add(1, 'hour'),
    end: null,
  });
  const { ref, ...rest } = register('event-private');
  const [mapOpened, setMapOpened] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null);
  const [myLocation, setMyLocation] = useState({ latitude: 0, longitude: 0 });

  const isVirtual = format == formats[1];

  const renderCategory = useCallback(
    (selected) => {
      if (category.length == 1)
        return categories.find((c) => c.id === category[0]).name;

      const render = [];
      category.sort().forEach((id) => {
        render.push(
          <span key={id} style={{ color: '#ff7d31', marginRight: '5px' }}>
            {categoryIcons[id - 1]}
          </span>
        );
      });

      return render;
    },
    [category, categories]
  );

  const fetchCategories = async () => {
    const result = await getCategories();
    if (result) setCategories(result.list);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCities = async () => {
    setCitiesLoading(true);

    const result = await getAllCities();
    setCities(
      result.map((c) => {
        return {
          id: c.id,
          label: c.name,
          city_code: c.kladr_id,
        };
      })
    );
    setCitiesLoading(false);
  };

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        successLocationHandler,
        errorLocationHandler
      );
    } else {
      alert('geolocation IS NOT available');
    }
  };

  const successLocationHandler = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setMyLocation({
      latitude,
      longitude,
    });
    mapRef.current.setCenter([latitude, longitude], 15);
    setCoords([latitude, longitude]);
  };

  const errorLocationHandler = (error) => {
    mapRef.current.setCenter([55.75406062131298, 37.62047914100069], 5);
    setCoords([55.75406062131298, 37.62047914100069]);

    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast(
          'Невозможно узнать ваше местоположение, необходимо дать доступ для определения точки на карте.',
          'warn'
        );
        break;
      case error.POSITION_UNAVAILABLE:
        toast(
          'Невозможно узнать ваше местоположение, необходимо дать доступ для определения точки на карте',
          'warn'
        );
        break;
      case error.TIMEOUT:
        toast('Вышло время ответа о получении вашего местоположения.', 'error');
        break;
      case error.UNKNOWN_ERROR:
        toast('Неизвестная ошибка о получении вашего местоположения.', 'error');
        break;
    }
  };

  const onAgeSliderInput = (event, newValue) => {
    setAgeLimits({ min: newValue[0], max: newValue[1] });
  };

  const onPeopleLimitsSliderInput = (event, newValue) => {
    setPeopleLimits({ min: newValue[0], max: newValue[1] });
  };

  const onMinAgeInput = (e) => {
    const value = parseInt(e.target.value);
    setAgeLimits({
      min: value,
      max: ageLimits.max,
    });
  };

  const onMaxAgeInput = (e) => {
    const value = parseInt(e.target.value);
    setAgeLimits({
      min: ageLimits.min,
      max: value,
    });
  };

  const onSwitchAgeLimits = (e) => {
    const checked = e.target.checked;
    setHasAgeLimits(checked);
  };

  const onMinPeopleInput = (e) => {
    const value = parseInt(e.target.value);
    setPeopleLimits({
      min: value,
      max: peopleLimits.max,
    });
  };

  const onMaxPeopleInput = (e) => {
    const value = parseInt(e.target.value);
    setPeopleLimits({
      min: peopleLimits.min,
      max: value,
    });
  };

  const onSwitchPeopleLimits = (e) => {
    const checked = e.target.checked;
    setHasPeopleLimits(checked);
  };

  const onCoodrsHandler = (lat, long) => {
    setCoords([lat, long]);
  };

  const onMapMove = (event) => {
    const tick = event.get('tick');

    const coords = mapRef.current.options
      .get('projection')
      .fromGlobalPixels(tick.globalPixelCenter, tick.zoom);

    setCoords([coords[0], coords[1]]);
  };

  const onMapLoad = () => {
    setMapLoaded(true);
    getCurrentLocation();
  };

  const openMap = () => {
    mapRef.current.events.add('actiontick', onMapMove);
    setMapOpened(true);
  };

  const closeMap = () => {
    mapRef.current.events.remove('actiontick', onMapMove);
    setMapOpened(false);
  };

  const onDateSelected = (f, l) => {
    if (l !== null && f !== null)
      if (l.isBefore(f)) {
        setData({
          start: f,
          end: f,
        });
        return;
      }

    setData({
      start: f,
      end: l,
    });
  };

  const onCategorySelect = (event) => {
    const value = event.target.value;

    if (value.length > 3) {
      toast('Максимум 3 категории', 'info');
      return;
    }
    setCategory(value);
  };

  const onSubmit = async (data) => {
    if (eventData.end == null) {
      toast('Вы забыли установить дату окончания мероприятия', 'warn');
      return;
    }

    if (category.length == 0) {
      toast('Необходимо указать категорию', 'warn');
      return;
    }

    const event = {
      name: data['event-name'],
      description: data['event-info'],
      privacy_type: privacy,
      category_type_ids: category,
      participant_min_age: hasAgeLimits ? ageLimits.min : '',
      participant_max_age: hasAgeLimits ? ageLimits.max : '',
      participant_min_count: hasPeopleLimits ? peopleLimits.min : '',
      participant_max_count: hasPeopleLimits ? peopleLimits.max : '',
      cost: parseInt(data['event-cost']),
      start_at: eventData.start.utc().format(),
      end_at: eventData.end.utc().format(),
      format,
      address: data['event-address'],
      city_id: isVirtual ? null : city,
      coordinate_latitude: isVirtual ? null : coords[0],
      coordinate_longitude: isVirtual ? null : coords[1],
    };

    setLoading(true);
    const result = await eventsStore.createEvent(event);

    if (result) {
      if (cover) {
        const image = new Image();

        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.setAttribute('width', 400);
          canvas.setAttribute('height', 150);
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, 400, 150);
          const dataUrl = canvas.toDataURL('image/jpeg');

          fetch(dataUrl)
            .then((res) => res.blob())
            .then(async (res) => {
              const coverResult = await saveEventCover(result.id, res);
              setLoading(false);
              navigate(`/event/${result.id}`);
            });
        };

        image.src = cover;
      } else {
        setLoading(false);
        navigate(`/event/${result.id}`);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles.createevent}>
      {loading && <Loader />}
      <div
        className={`${styles.mapContainer} ${mapOpened ? styles.opened : ''}`}
      >
        <div className={styles.map}>
          <BsFillGeoFill className={styles.center} size={32} />
          <div className={styles.coords}>
            <span>{coords[0]}</span>
            <span>{coords[1]}</span>
          </div>
          <SimpleButton
            classes={styles.closeMap}
            text="Выбрать"
            onClick={closeMap}
          />
          <YMaps query={{ apikey: YANDEX_API_KEY }}>
            <Map
              width="100%"
              height="100%"
              defaultState={{
                center: [myLocation.latitude, myLocation.longitude],
                zoom: 15,
                controls: ['zoomControl'],
              }}
              modules={['control.ZoomControl']}
              instanceRef={(ref) => (mapRef.current = ref)}
              onLoad={onMapLoad}
            />
          </YMaps>
        </div>
      </div>
      <div className={styles.window}>
        <p className={styles.title}>Создание нового мероприятия</p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={`${styles.formRow} ${styles.jcenter}`}>
            <ImageCropper callback={(image) => setCover(image)} />
          </div>
          <div className={styles.formRow}>
            <TextField
              label="Название"
              variant="outlined"
              fullWidth
              required
              error={errors['event-name']?.type === 'required'}
              inputProps={{
                minLength: 5,
                maxLength: 50,
              }}
              helperText={
                errors['event-name']?.type === 'required'
                  ? 'поле обязательно (мин. 5, макс. 100 символов)'
                  : 'мин. 5, макс. 100 символов'
              }
              {...register('event-name', {
                required: true,
                minLength: 5,
                maxLength: 100,
              })}
            />
          </div>
          <div className={styles.formRow}>
            <TextField
              label="Описание"
              variant="outlined"
              fullWidth
              required
              multiline
              error={errors['event-info']?.type === 'required'}
              inputProps={{
                minLength: 10,
                maxLength: 1000,
              }}
              helperText={
                errors['event-info']?.type === 'required'
                  ? 'поле обязательно (мин. 10, макс. 1000 символов)'
                  : 'мин. 10, макс. 1000 символов'
              }
              {...register('event-info', {
                required: true,
                minLength: 10,
                maxLength: 1000,
              })}
            />
          </div>
          <div className={styles.formRow}>
            <TextField
              label="Стоимость участия"
              fullWidth
              variant="outlined"
              defaultValue={0}
              inputProps={{
                type: 'number',
                min: 0,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">руб.</InputAdornment>
                ),
              }}
              {...register('event-cost')}
            />
          </div>
          <div className={styles.formRow}>
            <FormControl fullWidth>
              <InputLabel id="multiple-checkbox-cat">Категория *</InputLabel>
              <Select
                labelId="multiple-checkbox-cat"
                multiple
                fullWidth
                required
                value={category}
                onChange={onCategorySelect}
                input={<OutlinedInput label="Категория *" />}
                renderValue={renderCategory}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    <ListItemIcon
                      sx={{
                        color: category.includes(cat.id) ? '#ff7d31' : '',
                      }}
                    >
                      {categoryIcons[cat.id - 1]}
                    </ListItemIcon>
                    <ListItemText primary={cat.name} title={cat.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.formRow}>
            <ToggleButtonGroup
              color="primary"
              value={privacy}
              exclusive
              onChange={(e, value) =>
                value !== null ? setPrivacy(value) : null
              }
            >
              <ToggleButton value="PU">
                <span style={{ marginRight: '5px' }}>для всех</span>
                <BsFillUnlockFill size={20} />
              </ToggleButton>
              <ToggleButton value="PR">
                <span style={{ marginRight: '5px' }}>для подписчиков</span>
                <BsFillLockFill size={20} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={styles.formRow}>
            <label className={`${styles.label} ${styles.ageLabel}`}>
              Возрастная группа
            </label>
            <div className={styles.ageRangeBlock}>
              <Input
                className={styles.ageLimitInput}
                value={ageLimits.min}
                size="small"
                onChange={onMinAgeInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 99,
                  type: 'number',
                }}
                disabled={!hasAgeLimits}
              />
              <Slider
                className={styles.range}
                value={[ageLimits.min, ageLimits.max]}
                onChange={onAgeSliderInput}
                disabled={!hasAgeLimits}
                min={1}
                max={99}
              />
              <Input
                className={styles.ageLimitInput}
                value={ageLimits.max}
                size="small"
                onChange={onMaxAgeInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 99,
                  type: 'number',
                }}
                disabled={!hasAgeLimits}
              />
              <div>
                <Switch checked={hasAgeLimits} onChange={onSwitchAgeLimits} />
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <label className={`${styles.label} ${styles.countLabel}`}>
              Количество участников
            </label>
            <div className={styles.peopleRangeBlock}>
              <Input
                className={styles.peopleLimitInput}
                value={peopleLimits.min}
                size="small"
                onChange={onMinPeopleInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999,
                  type: 'number',
                }}
                disabled={!hasPeopleLimits}
              />
              <Slider
                className={styles.range}
                value={[peopleLimits.min, peopleLimits.max]}
                onChange={onPeopleLimitsSliderInput}
                disabled={!hasPeopleLimits}
                min={1}
                max={999}
              />
              <Input
                className={styles.peopleLimitInput}
                value={peopleLimits.max}
                size="small"
                onChange={onMaxPeopleInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999,
                  type: 'number',
                }}
                disabled={!hasPeopleLimits}
              />
              <div>
                <Switch
                  checked={hasPeopleLimits}
                  onChange={onSwitchPeopleLimits}
                />
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <ToggleButtonGroup
              color="primary"
              value={format}
              exclusive
              onChange={(e, value) =>
                value !== null ? setFormat(value) : null
              }
            >
              <ToggleButton value={formats[0]}>
                <span style={{ marginRight: '5px' }}>Реальное</span>
                <BsGlobeEuropeAfrica size={20} />
              </ToggleButton>
              <ToggleButton value={formats[1]}>
                <span style={{ marginRight: '5px' }}>Виртуальное</span>
                <BsGlobe2 size={20} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={styles.formRow}>
            <Autocomplete
              disablePortal
              onChange={(event, options) => {
                setCity(options?.id || null);
              }}
              options={cities}
              fullWidth
              disabled={isVirtual}
              loading={citiesLoading}
              loadingText={'Загрузка городов'}
              noOptionsText={'Нет совпадений'}
              openText={'Показать список городов'}
              getOptionKey={(option) => option.id}
              onFocus={() => {
                if (cities.length == 0 && !citiesLoading) fetchCities();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={!isVirtual}
                  error={errors['event-city']?.type === 'required'}
                  helperText={
                    errors['event-adress']?.type === 'required' &&
                    'поле обязательно'
                  }
                  {...register('event-city', {
                    required: !isVirtual,
                  })}
                  label="Город"
                />
              )}
            />
          </div>
          <div className={styles.formRow}>
            <TextField
              label={
                format == formats[0] ? 'Физический адрес' : 'Адрес в интернете'
              }
              variant="outlined"
              fullWidth
              required
              error={errors['event-address']?.type === 'required'}
              inputProps={{
                minLength: 10,
                maxLength: 250,
              }}
              helperText={
                errors['event-adress']?.type === 'required'
                  ? 'поле обязательно (мин. 10, макс. 250 символов)'
                  : 'мин. 10, макс. 250 символов'
              }
              {...register('event-address', {
                required: true,
                minLength: 10,
                maxLength: 250,
              })}
            />
          </div>
          <div className={styles.formRow}>
            <IconButton
              icon={<BsPinMapFill size={18} color="white" />}
              iconPos="end"
              type="submit"
              text="точка на карте"
              classes={styles.mapBtn}
              onClick={openMap}
              disabled={isVirtual && !mapLoaded}
            />
            <div className={styles.coords}>
              <div className={styles.coord}>
                <span>N</span>
                <input
                  value={coords[0]}
                  disabled={isVirtual}
                  onKeyPress={(event) => {
                    if (!/[0-9]|\./.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="широта"
                  onChange={(e) => onCoodrsHandler(e.target.value, coords[1])}
                />
              </div>
              <div className={styles.coord}>
                <span>E</span>
                <input
                  value={coords[1]}
                  disabled={isVirtual}
                  onKeyPress={(event) => {
                    if (!/[0-9]|\./.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="долгота"
                  onChange={(e) => onCoodrsHandler(coords[0], e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Дата</label>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="ru"
              localeText={ruLocale}
            >
              <div className={styles.timeDataPickers}>
                <MobileDateTimePicker
                  label="начало *"
                  ampm={false}
                  value={eventData.start}
                  required
                  onChange={(value) => onDateSelected(value, eventData.end)}
                />
                <MobileDateTimePicker
                  label="окончание *"
                  ampm={false}
                  value={eventData.end}
                  required
                  onChange={(value) => {
                    if (value.isBefore(eventData.start)) {
                      toast(
                        'Время окончания не может быть раньше времени начала мероприятия',
                        'warn'
                      );
                    }
                    onDateSelected(eventData.start, value);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
          <SimpleButton
            type="submit"
            text="Создать"
            classes={styles.createBtn}
            onClick={handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </div>
  );
});

export default CreateEvent;
