import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useProfileStore } from '../../store/index';

import styles from './styles.module.scss';
import ProfileModal from '../../components/profileModal';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { BsChevronDown } from 'react-icons/bs';

import Loader from '../../ui/components/loader';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { Divider, TextField } from '@mui/material';
import { toast } from '../../helpers/utils';

const Settings = observer(() => {
  const [expanded, setExpanded] = useState(0);
  const [profileHasChanged, setProfileHasChanged] = useState(false);

  const profileStore = useProfileStore();

  const [firstName, setFirstName] = useState(profileStore.firstName);
  const [lastName, setLastName] = useState(profileStore.lastName);
  const [middleName, setMiddleName] = useState(profileStore.middleName);
  const [description, setDescription] = useState(profileStore.description);

  let profileUrl = `https://meropriatnik.ru/user/${profileStore.id}`;

  if (window.Telegram?.WebView.initParams)
    profileUrl = `https://t.me/Meropriatnik_bot/meropriatnik?startapp=user_${profileStore.id}`;

  const onProfileUrlClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = profileUrl;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      toast('Адрес скопирован', 'info');
    } catch (err) {
    } finally {
      document.body.removeChild(textarea);
    }
  };

  useMemo(() => {
    let changed = false;
    changed =
      profileStore.firstName !== firstName ||
      profileStore.lastName !== lastName ||
      profileStore.middleName !== middleName ||
      profileStore.description !== description;

    setProfileHasChanged(changed);
  }, [firstName, lastName, middleName, description]);

  const saveProfileHandler = () => {
    setLoading(true);
    const result = profileStore.saveProfile({
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      description,
    });
    if (result) {
      setProfileHasChanged(false);
    }
    setLoading(false);
  };

  const handleAccordion = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : -1);
  };

  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.settings}>
      {loading && <Loader />}
      <Accordion expanded={expanded == 0} onChange={handleAccordion(0)}>
        <AccordionSummary
          className={styles.accordionTitle}
          expandIcon={<BsChevronDown />}
        >
          Настройки профиля
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <TextField
            className={styles.profileUrl}
            label="Cылка на профиль"
            variant="outlined"
            type="text"
            value={profileUrl}
            onClick={() => onProfileUrlClick()}
          />

          <TextField
            label="Имя"
            variant="outlined"
            type="text"
            value={firstName}
            onInput={(e) => setFirstName(e.target.value)}
          />

          <TextField
            label="Фамилия"
            variant="outlined"
            type="text"
            value={lastName}
            onInput={(e) => setLastName(e.target.value)}
          />
          <TextField
            label="Отчество"
            variant="outlined"
            type="text"
            value={middleName}
            onInput={(e) => setMiddleName(e.target.value)}
          />
          <TextField
            label="О себе"
            variant="outlined"
            type="text"
            value={description}
            onInput={(e) => setDescription(e.target.value)}
          />
          <Divider />
          <SimpleButton
            text="Сохранить изменения"
            disabled={!profileHasChanged}
            onClick={saveProfileHandler}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded == 1} onChange={handleAccordion(1)}>
        <AccordionSummary
          disabled
          className={styles.accordionTitle}
          expandIcon={<BsChevronDown />}
        >
          Смена пароля
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <TextField label="Прежний пароль" variant="outlined" type="text" />
          <TextField label="Новый пароль" variant="outlined" type="text" />
          <TextField
            label="Повторите новый пароль"
            variant="outlined"
            type="text"
          />
          <Divider />
          <SimpleButton text="Сменить пароль" />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded == 1} onChange={handleAccordion(1)}>
        <AccordionSummary
          disabled
          className={styles.accordionTitle}
          expandIcon={<BsChevronDown />}
        >
          Настройки приватности
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
    </div>
  );
});

export default Settings;
