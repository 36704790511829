import React, { useEffect, useState, lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react-lite';
import { useProfileStore, useEventsStore } from '../../store/index';
import { useNavigate } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';

import styles from './styles.module.scss';

import {
  BsCalendar2CheckFill,
  BsCalendar2WeekFill,
  BsCalendar2HeartFill,
} from 'react-icons/bs';
import EventCard from '../../ui/components/eventCard';
import {
  acceptFollower,
  cancelFollower,
  getMyParticipants,
  getMyPendings,
  getMySubscribes,
  unSubscribeUser,
} from '../../helpers/fetch';
import UserListModal from '../../ui/components/usersListModal';
import AlertDialog from '../../ui/components/dialog';
import Loader from '../../ui/components/loader';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import UserInfoPanel from '../../components/userInfoPanel';

const ImageUpload = lazy(() => import('../../components/imageUpload'));

let pendings = [];
let followers = [];
let subscribes = [];

const Profile = observer(() => {
  const profileStore = useProfileStore();
  const eventsStore = useEventsStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [eventsTabIndex, setEventsTabIndex] = useState(0);
  const [tabEvents, setTabEvents] = useState([]);

  const [avatarUpload, setAvatarUpload] = useState(false);

  const [usersModal, setUsersModal] = useState(null);
  const [promptDialog, setPromptDialog] = useState(null);

  useEffect(() => {
    switch (eventsTabIndex) {
      case 0:
        setTabEvents(eventsStore.myEvents);
        break;
      case 1:
        setTabEvents(eventsStore.mySubscribeEvents);
        break;
      case 2:
        setTabEvents(eventsStore.bookmarks);
        break;
    }
  }, [
    eventsStore.myEvents,
    eventsStore.mySubscribeEvents,
    eventsStore.bookmarks,
  ]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      const events = await eventsStore.getMyEvents(['categories']);
      if (!events) return;

      const subscribeEventsResponse = await eventsStore.getMySubscribeEvents([
        'categories',
      ]);
      if (!subscribeEventsResponse) return;

      const bookmarksResponse = await eventsStore.getMyBookmarks([
        'categories',
      ]);
      if (!bookmarksResponse) return;

      setLoading(false);
    }

    fetchData();
  }, []);

  const fetchFollowers = async () => {
    setLoading(true);

    followers = [];
    let offset = 0;

    async function getFollowers() {
      const result = await getMyParticipants(
        'profile.avatar.file',
        100 * offset
      );
      if (!result) {
        return;
      }

      followers.push(...result.list);
      if (followers.length < result.total_count) {
        offset++;
        await getFollowers();
      }
    }

    await getFollowers();

    pendings = [];
    offset = 0;
    async function getPendings() {
      const result = await getMyPendings('profile.avatar.file', 100 * offset);
      if (!result) {
        return;
      }

      pendings.push(...result.list);
      if (pendings.length < result.total_count) {
        offset++;
        await getPendings();
      }
    }

    await getPendings();
    setLoading(false);
    showFollowers();
  };

  const fetchSubscribes = async () => {
    setLoading(true);

    subscribes = [];
    let offset = 0;

    async function getSubscribes() {
      const result = await getMySubscribes(
        'user.profile.avatar.file',
        100 * offset
      );
      if (!result) {
        return;
      }

      subscribes.push(...result.list);
      if (subscribes.length < result.total_count) {
        offset++;
        await getSubscribes();
      }
    }

    await getSubscribes();
    setLoading(false);
    showSubscribes();
  };

  const openAvatarUploader = () => {
    setAvatarUpload(true);
  };

  const onAvatarUploaderClose = async (imgBase64) => {
    setAvatarUpload(false);

    if (imgBase64) {
      const image = new Image();

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.setAttribute('width', 200);
        canvas.setAttribute('height', 200);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, 200, 200);
        const dataUrl = canvas.toDataURL('image/png');

        fetch(dataUrl)
          .then((res) => res.blob())
          .then(async (res) => {
            const success = await profileStore.saveAvatar(res);
          });
      };

      image.src = imgBase64;
    }
  };

  const onChangeEventTab = (event, newValue) => {
    setEventsTabIndex(newValue);
    switch (newValue) {
      case 0:
        setTabEvents(eventsStore.myEvents);
        break;
      case 1:
        setTabEvents(eventsStore.mySubscribeEvents);
        break;
      case 2:
        setTabEvents(eventsStore.bookmarks);
        break;
    }
  };
  const showFollowers = () => {
    setUsersModal({
      title: ['Мои подписчики', 'Заявки'],
      users: [followers, pendings],
      subscribes: false,
      acceptHandler: _acceptFollower,
      cancelHandler: cancelFollowerPrompt,
    });
  };

  const showSubscribes = () => {
    const acceptedSubscribes = subscribes
      .filter((s) => s.accepted_at !== null)
      .map((s) => s.user);
    const pendingSubscribes = subscribes
      .filter((s) => s.accepted_at == null)
      .map((s) => s.user);

    setUsersModal({
      title: ['Подписки', 'На рассмотрении'],
      users: [acceptedSubscribes, pendingSubscribes],
      subscribes: true,
      acceptHandler: null,
      cancelHandler: cancelSubscribesPropmt,
    });
  };

  const closeUsersModal = () => {
    setUsersModal(null);
  };

  const openEvent = (eventId) => {
    if (window.Telegram?.WebView.initParams) {
      const eventUrl = `https://t.me/Meropriatnik_bot/meropriatnik?startapp=event_${eventId}`;
      window.Telegram.WebApp.openTelegramLink(eventUrl);
      return;
    }

    window.open(`/event/${eventId}`, '_blank');
  };

  const openUserProfile = (userId) => {
    navigate(`/user/${userId}`);
  };

  const _acceptFollower = async (userId) => {
    const success = await acceptFollower(userId);

    if (success) {
      const newFollower = pendings.find((f) => f.profile.user_id == userId);
      followers = [...followers, newFollower];
      pendings = pendings.filter((p) => p.profile.user_id !== userId);
      profileStore.followersCount++;
      profileStore.pendingCount--;

      showFollowers();
    }
  };

  const cancelFollowerPrompt = (userId) => {
    setPromptDialog({
      title: 'Удалить подписчика',
      description: 'Точно хотите удалить подписчика?',
      onAccept: () => _cancelFollower(userId),
      onCancel: () => closePromptDialog(),
    });
  };

  const _cancelFollower = async (userId) => {
    setLoading(true);
    const success = await cancelFollower(userId);
    closePromptDialog();
    setLoading(false);

    if (success) {
      pendings = pendings.filter((p) => p.profile.user_id !== userId);
      followers = followers.filter((f) => f.profile.user_id !== userId);
      profileStore.followersCount--;
      showFollowers();
    }
  };

  const cancelSubscribesPropmt = (userId) => {
    setPromptDialog({
      title: 'Отписаться',
      description: 'Точно хотите отписаться?',
      onAccept: () => _cancelSubscribes(userId),
      onCancel: () => closePromptDialog(),
    });
  };

  const _cancelSubscribes = async (userId) => {
    setLoading(true);
    const success = await unSubscribeUser(userId);
    closePromptDialog();
    setLoading(false);

    if (success) {
      subscribes = subscribes.filter((s) => s.user.profile.user_id !== userId);
      profileStore.subscribesCount--;
      showSubscribes();
    }
  };

  const closePromptDialog = () => {
    setPromptDialog(null);
  };

  return (
    <>
      {loading && <Loader />}
      {usersModal && (
        <UserListModal
          owner={true}
          {...usersModal}
          opened={usersModal !== null}
          openUserProfile={openUserProfile}
          onClose={closeUsersModal}
        />
      )}
      {promptDialog && (
        <AlertDialog
          title={promptDialog.title}
          description={promptDialog.description}
          onAccept={promptDialog.onAccept}
          onCancel={promptDialog.onCancel}
        />
      )}
      <div className={styles.profile}>
        {avatarUpload && (
          <Suspense fallback={<Loader />}>
            {createPortal(
              <ImageUpload
                callback={onAvatarUploaderClose}
                label="Выберите фото"
              />,
              document.querySelector('#portal')
            )}
          </Suspense>
        )}
        <UserInfoPanel
          self={true}
          userData={profileStore}
          fetchFollowers={fetchFollowers}
          fetchSubscribes={fetchSubscribes}
          openAvatarUploader={openAvatarUploader}
        />
        <div className={styles.events}>
          <Tabs
            value={eventsTabIndex}
            onChange={onChangeEventTab}
            sx={{ minHeight: '35px' }}
            centered
          >
            <Tab
              icon={<BsCalendar2CheckFill />}
              iconPosition="start"
              sx={{ minHeight: '35px', padding: '5px' }}
              label="МОИ"
            />
            <Tab
              icon={<BsCalendar2WeekFill />}
              iconPosition="start"
              sx={{ minHeight: '35px', padding: '5px' }}
              label="УЧАСТВУЮ"
            />
            {/* <Tab
              icon={<BsCalendar2HeartFill />}
              iconPosition="start"
              sx={{ minHeight: '35px', padding: '5px' }}
              label="ИЗБРАННОЕ"
            /> */}
          </Tabs>
          <div
            className={`${styles.eventsList} ${
              tabEvents.length == 0 ? styles.noEvents : ''
            }`}
          >
            {tabEvents.length == 0 ? (
              eventsTabIndex == 0 ? (
                <div className={styles.warning}>
                  У вас ещё нет ни одного мероприятия
                  <br />
                  <br />
                  <SimpleButton
                    text="создать"
                    onClick={() => navigate('/create')}
                  />
                </div>
              ) : eventsTabIndex == 1 ? (
                <div className={styles.warning}>
                  Вы не участвуете ещё ни в одном мероприятии
                  <br />
                  <br />
                  <SimpleButton
                    text="найти мероприятие"
                    onClick={() => navigate('/events')}
                  />
                </div>
              ) : (
                <div className={styles.warning}>
                  У вас нет избранных мероприятий
                  <br />
                  <br />
                  <SimpleButton
                    text="найти мероприятие"
                    onClick={() => navigate('/events')}
                  />
                </div>
              )
            ) : (
              tabEvents.map((event) => (
                <EventCard key={event.id} event={event} onClick={openEvent} />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Profile;
