import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useFiltersStore } from '../../store/index';
import {
  BsMapFill,
  BsCardList,
  BsFilterCircleFill,
  BsSortDown,
} from 'react-icons/bs';

import { Tabs, Tab } from '@mui/material';

import MapEvents from '../../components/mapEvents';
import styles from './styles.module.scss';
import ListEvents from '../../components/listEvents';
import IconButton from '../../ui/buttons/iconButton';
import Filters from '../../components/filters';

const Events = observer(() => {
  const filtersStore = useFiltersStore();

  const [eventsTab, setEventsTab] = useState(0);
  const [filters, setFilters] = useState(filtersStore.filters);
  const [filtersModal, setFiltersModal] = useState(false);

  const onTabChange = (e, val) => {
    setEventsTab(val);
  };

  const onCloseFiltersModal = () => {
    setFiltersModal(false);
  };

  const onAcceptFilters = (filters) => {
    setFilters(filters);
    filtersStore.saveFilters(filters);
    onCloseFiltersModal();
  };

  const openEvent = (eventId) => {
    if (window.Telegram?.WebView.initParams) {
      const eventUrl = `https://t.me/Meropriatnik_bot/meropriatnik?startapp=event_${eventId}`;
      window.Telegram.WebApp.openTelegramLink(eventUrl);
      return;
    }

    window.open(`/event/${eventId}`, '_blank');
  };

  return (
    <div className={styles.events}>
      {filtersModal && (
        <Filters
          filters={filters}
          isMap={eventsTab == 1}
          onCancel={onCloseFiltersModal}
          onAccept={onAcceptFilters}
        />
      )}
      <Tabs
        className={styles.tabs}
        value={eventsTab}
        onChange={onTabChange}
        centered
        sx={{ minHeight: '35px' }}
      >
        <Tab
          icon={<BsCardList />}
          iconPosition="start"
          sx={{ minHeight: '35px' }}
          label="Список"
        />
        <Tab
          icon={<BsMapFill />}
          iconPosition="start"
          sx={{ minHeight: '35px' }}
          label="Карта"
        />
      </Tabs>
      <div className={styles.filters}>
        <IconButton
          text="фильтры"
          icon={<BsFilterCircleFill size={18} />}
          iconPos="start"
          classes={styles.filterBtn}
          onClick={() => setFiltersModal(true)}
        />
        <IconButton
          text="сортировка"
          disabled={true}
          icon={<BsSortDown size={18} />}
          iconPos="start"
          classes={styles.sortBtn}
        />
      </div>
      {eventsTab == 0 && (
        <div className={styles.content}>
          <ListEvents filters={filters} onEventOpen={openEvent} />
        </div>
      )}
      {eventsTab == 1 && (
        <div className={styles.content}>
          <MapEvents filters={filters} onEventOpen={openEvent} />
        </div>
      )}
    </div>
  );
});

export default Events;
