import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import AuthStore from './auth';
import { toast } from '../helpers/utils';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

class EventsStore {
  myEvents = [];
  mySubscribeEvents = [];
  bookmarks = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getMyEvents(relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/my/many/ownership/get`,
        { relationships },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setMyEvents(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getMySubscribeEvents(relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/my/many/participation/get`,
        { relationships },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setMySubscribeEvents(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getEvent(eventId, relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/${eventId}/one/get`,
        { relationships: relationships },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getMyBookmarks(relationships) {
    return true;

    return axios
      .postForm(
        `${API_ENDPOINT}/bookmarks/my/many/ownership/get`,
        { relationships },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setMyBookmarks(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async deleteEvent(eventId) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/${eventId}/delete`,
        {},
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.removeEvent(eventId);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  setMyEvents(data) {
    this.myEvents = data.list;
  }

  setMySubscribeEvents(data) {
    this.mySubscribeEvents = data.list.filter(
      (event) => event.deleted_at === null
    );
  }

  setMyBookmarks(data) {
    this.bookmarks = data.list;
  }

  removeEvent(eventId) {
    this.myEvents = this.myEvents.filter((e) => e.id !== eventId);
    this.mySubscribeEvents = this.mySubscribeEvents.filter(
      (e) => e.id !== eventId
    );
    this.bookmarks = this.bookmarks.filter((e) => e.id !== eventId);
  }

  async createEvent(data) {
    return axios
      .postForm(`${API_ENDPOINT}/events/new/save`, data, {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      })
      .then((response) => {
        this.addNewEvent(response.data);
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  addNewEvent(data) {
    this.myEvents.push(data);
  }

  errorHandler(data) {
    if (data.response?.data) toast(data.response.data.message, 'error');
    else toast('Неизвестная ошибка', 'error');
  }
}

const store = new EventsStore();

export default store;
