import moment from 'moment';

export function toast(msg, type) {
  switch (type) {
    case 'success':
      window.PrimeToast.success(msg);
      break;
    case 'error':
      window.PrimeToast.error(msg);
      break;
    case 'warn':
      window.PrimeToast.warn(msg);
      break;
    case 'info':
      window.PrimeToast.info(msg);
      break;
    default:
      window.PrimeToast.info(msg);
  }
}

export function getUserLabel(user) {
  if (user == null) return '';

  let userLabel = user.username;

  if (user.username === null) {
    if (
      !(
        user.first_name == null &&
        user.last_name == null &&
        user.middle_name == null
      )
    )
      userLabel = `${user.last_name} ${user.first_name} ${user.middle_name}`;
    else userLabel = `#${user.user_id}`;
  }

  return userLabel;
}

export function isSameDay(firstDate, secondDate) {
  if (
    firstDate.year() == secondDate.year() &&
    firstDate.month() == secondDate.month() &&
    firstDate.date() == secondDate.date()
  )
    return true;

  return false;
}

export function leftTime(nowTime, futureTime) {
  const year = futureTime.diff(nowTime, 'years');
  const month = futureTime.diff(nowTime, 'months');
  const day = futureTime.diff(nowTime, 'days');
  const hour = futureTime.diff(nowTime, 'hours');
  const min = futureTime.diff(nowTime, 'minutes');

  let leftTimeStr = '';

  if (year != 0) leftTimeStr = year + ` ${yearTitle(year)}`;
  else if (month != 0) leftTimeStr = month + ' мес.';
  else if (day != 0) leftTimeStr = day + ` ${dayTitle(day)}`;
  else if (hour != 0) leftTimeStr = hour + ' ч.';
  else leftTimeStr = min + ' мин.';

  return leftTimeStr;
}

function yearTitle(number) {
  const titles = ['год', 'года', 'лет'];
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

function dayTitle(number) {
  let last_num;
  if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return 'дней';
  last_num = number % 10;
  if (last_num == 1) return 'день';
  if ([2, 3, 4].includes(last_num)) return 'дня';
  if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'дней';
}
