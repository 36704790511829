import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Tabs, Tab } from '@mui/material';
import { getUserLabel } from '../../../helpers/utils';
import { BsFillTrashFill } from 'react-icons/bs';
import styles from './styles.module.scss';
import Avatar from '../avatar';

const User = (props) => {
  const { owner, user, subscribes, onClick, bid, onAccept, onCancel } = props;

  const acceptHandler = (e) => {
    e.stopPropagation();
    if (user.profile) onAccept(user.profile.user_id);
  };

  const cancelHandler = (e) => {
    e.stopPropagation();
    if (user.profile) onCancel(user.profile.user_id);
  };

  return (
    <div
      className={styles.user}
      onClick={() => (user.profile ? onClick(user.profile.user_id) : null)}
    >
      <Avatar avatar={user.profile?.avatar?.file.url} size="30" />
      <span className={styles.name}>
        {user.profile ? getUserLabel(user.profile) : 'профиль не заполнен'}
      </span>
      {!bid && owner && (
        <div className={styles.cancel} onClick={cancelHandler}>
          <BsFillTrashFill size={26} />
        </div>
      )}
      {bid && owner && (
        <>
          {!subscribes && (
            <div className={styles.accept} onClick={acceptHandler}>
              Принять
            </div>
          )}
          <div className={styles.cancel} onClick={cancelHandler}>
            <BsFillTrashFill size={26} />
          </div>
        </>
      )}
    </div>
  );
};

const UserListModal = (props) => {
  const {
    owner,
    title,
    users,
    subscribes,
    opened,
    openUserProfile,
    acceptHandler,
    cancelHandler,
    onClose,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [animationStart, setAnimationStart] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);

  useEffect(() => {
    if (users.length > 1) {
      if (users[0].length === 0 && users[1].length !== 0) setTabIndex(1);
    }
  }, []);

  const onOverlayClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  const spring = useSpring({
    delay: 0,
    from: { top: '100%' },
    to: { top: '50%' },
    onStart: (e) => {
      onAnimationStart();
    },
    onRest: (e) => {
      if (e.finished === true) onAnimationFinish();
    },
    config: { tension: 170, friction: 26, mass: 0.5 },
  });

  const onAnimationStart = () => {
    setAnimationStart(true);
  };

  const onAnimationFinish = () => {
    setAnimationEnded(true);
  };

  if (!opened) return;

  return (
    <div
      className={`${styles.overlay}`}
      style={{
        opacity: animationStart ? 1 : 0,
        pointerEvents: animationEnded ? '' : 'none',
      }}
      onClick={onOverlayClick}
    >
      <animated.div className={styles.modal} style={{ ...spring }}>
        {Array.isArray(title) ? (
          <Tabs
            value={tabIndex}
            onChange={(e, val) => setTabIndex(val)}
            centered
          >
            {title.map((text, index) => (
              <Tab
                key={text}
                label={text}
                disabled={users[index].length == 0}
              />
            ))}
          </Tabs>
        ) : (
          <div className={styles.title}>{title}</div>
        )}
        <div className={styles.usersList}>
          {users?.[tabIndex].map((user) => (
            <User
              key={user.id}
              user={user}
              onClick={openUserProfile}
              bid={tabIndex == 1}
              owner={owner}
              subscribes={subscribes}
              onAccept={acceptHandler}
              onCancel={cancelHandler}
            />
          ))}
        </div>
      </animated.div>
    </div>
  );
};

export default UserListModal;
