import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useProfileStore, useAuthStore } from '../../store';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { toast } from '../../helpers/utils';
import { TextField } from '@mui/material';

import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import 'moment/locale/ru';
import moment from 'moment';

const ruLocale =
  ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const ProfileModal = observer((props) => {
  const { onClose } = props;
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [description, setDescription] = useState('');
  const [birthDate, setBirthDate] = useState(null);
  const [sex, setSex] = useState('M');

  const profileStore = useProfileStore();
  const authStore = useAuthStore();

  useEffect(() => {
    if (authStore.isTelegramAuth) {
      const initParams = JSON.parse(
        new URLSearchParams(
          window.Telegram.WebView.initParams.tgWebAppData
        ).get('user')
      );
      setUserName(initParams.username || '');
      setFirstName(initParams.first_name || '');
      setLastName(initParams.last_name || '');
    }
  }, []);

  const onUserNameChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z0-9]/gi, '');
    setUserName(value);
  };

  const onFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const onLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
  };

  const onMiddleNameChange = (e) => {
    const value = e.target.value;
    setMiddleName(value);
  };

  const onBirthSelected = (date) => {
    if (moment().isBefore(date)) {
      toast('Дата рождения не может быть больше текущей даты', 'warn');
      return;
    }

    setBirthDate(date);
  };

  const onMaleSelect = () => {
    setSex('M');
  };

  const onFemaleSelect = () => {
    setSex('F');
  };

  const saveProfileInfo = async () => {
    if (!saveProfileCheck()) return;

    const data = {
      username: userName,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      description: description,
      sex,
      birth_date: birthDate.format('YYYY-MM-DD'),
    };

    const success = await profileStore.saveProfile(data);
    if (success) {
      onClose();
    }
  };

  const saveProfileCheck = () => {
    let allGood = true;

    if (userName.length <= 3) {
      toast('Имя пользователя должно быть больше 3 символов', 'warn');
      allGood = false;
    }

    if (birthDate == null) {
      toast('Установите дату рождения', 'warn');
      allGood = false;
    }

    return allGood;
  };

  return (
    <div className={styles.profileModal}>
      <div className={styles.window}>
        <p className={styles.title}>Сперва заполните свой профиль</p>
        <div className={styles.content}>
          <TextField
            label="Никнейм"
            variant="outlined"
            type="text"
            required
            value={userName}
            fullWidth
            onInput={onUserNameChange}
          />
          <TextField
            label="Имя"
            variant="outlined"
            type="text"
            value={firstName}
            fullWidth
            onInput={onFirstNameChange}
          />
          <TextField
            label="Фамилия"
            variant="outlined"
            type="text"
            value={lastName}
            fullWidth
            onInput={onLastNameChange}
          />
          <TextField
            label="Отчество"
            variant="outlined"
            type="text"
            value={middleName}
            fullWidth
            onInput={onMiddleNameChange}
          />
          <TextField
            label="О себе"
            variant="outlined"
            type="text"
            value={description}
            fullWidth
            multiline
            onInput={(e) => setDescription(e.target.value)}
          />
          <div className={styles.birthPicker}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="ru"
              localeText={ruLocale}
            >
              <DesktopDatePicker
                label="Дата рождения *"
                value={birthDate}
                desktopModeMediaQuery=""
                onChange={onBirthSelected}
              />
            </LocalizationProvider>
          </div>
          <div className={styles.row}>
            <label>Пол</label>
            <BsGenderFemale
              className={`${styles.female} ${
                sex === 'F' ? styles.selected : ''
              }`}
              title="женский"
              onClick={onFemaleSelect}
            />
            <BsGenderMale
              className={`${styles.male} ${sex === 'M' ? styles.selected : ''}`}
              title="мужской"
              onClick={onMaleSelect}
            />
            <span className={styles.sexText}>
              {sex === 'M' ? 'мужской' : 'женский'}
            </span>
          </div>
        </div>
        <SimpleButton
          classes={styles.submitBtn}
          onClick={saveProfileInfo}
          text="Сохранить"
        />
      </div>
    </div>
  );
});

export default ProfileModal;
