import { BsFillLockFill, BsFillUnlockFill, BsCashCoin } from 'react-icons/bs';
import { categoryIcons } from '../../../helpers/caterogy';
import styles from './styles.module.scss';
import moment from 'moment';

const EventCard = (props) => {
  const { event, onClick } = props;

  const startTime = moment(event.start_at);
  const endTime = moment(event.end_at);
  const nowTime = moment();

  let timeLabel = '';
  let timeStyle = '';

  if (startTime < nowTime && nowTime < endTime) {
    timeLabel = 'сейчас идёт';
    timeStyle = styles.inTime;
  } else if (nowTime > endTime) {
    timeLabel = 'завершено';
    timeStyle = styles.outOfTime;
  }

  const needYear =
    startTime.year() == nowTime.year() && endTime.year() == nowTime.year()
      ? ' в hh:mm'
      : ' YYYYг.';

  return (
    <div className={styles.event} onClick={() => onClick(event.id)}>
      <div className={styles.header}>
        <div className={styles.privacy}>
          {event.privacy_type === 'PU' ? (
            <BsFillUnlockFill title="публичное" />
          ) : (
            <BsFillLockFill title="приватное" />
          )}
        </div>
        {event.cost > 0 && (
          <div className={styles.cost} title={`участие ${event.cost} рублей`}>
            <BsCashCoin />
          </div>
        )}
        <div className={styles.category}>
          {event.categories?.map((cat) => (
            <span key={cat.id}>{categoryIcons[cat.type_id - 1]}</span>
          ))}
        </div>
      </div>
      <div className={styles.name}>{event.name}</div>
      <div className={`${styles.footer} ${timeStyle}`}>
        <div className={styles.timeLabel}>{timeLabel}</div>
        <span>{startTime.format(`Do MMM${needYear}`)}</span>
      </div>
    </div>
  );
};

export default EventCard;
