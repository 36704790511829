import { useState } from 'react';
import { createPortal } from 'react-dom';

import TelegramSvg from '../../../assets/imgs/share/telegram.svg';
import VkSvg from '../../../assets/imgs/share/vk.svg';
import MailSvg from '../../../assets/imgs/share/mail.svg';
import WhatsAppSvg from '../../../assets/imgs/share/whatsapp.svg';

import { QRCode } from 'react-qrcode-logo';
import { BsQrCode } from 'react-icons/bs';

import styles from './styles.module.scss';

export default function Share(props) {
  const { eventId, shareText } = props;
  const [qrCode, setQrCode] = useState(false);

  const width = Math.min(window.innerWidth - 50, 600);
  const height = Math.min(window.innerHeight - 100, 500);
  const left = window.innerWidth / 2 - 325;

  let shareUrl = `https://meropriatnik.ru/event/${eventId}`;

  if (window.Telegram?.WebView.initParams)
    shareUrl = `https://t.me/Meropriatnik_bot/meropriatnik?startapp=event_${eventId}`;

  const options = `status=0,toolbar=0,width=${width},height=${height},top=100,left=${left}`;

  return (
    <>
      {qrCode &&
        createPortal(
          <div
            className={styles.qrCodeOverlay}
            onClick={() => setQrCode(false)}
          >
            <QRCode
              value={shareUrl}
              fgColor="#ff7d31"
              qrStyle="dots"
              eyeRadius={10}
              size={window.innerWidth / 1.5}
              quietZone={20}
            />
          </div>,
          document.querySelector('#portal')
        )}
      <div className={styles.share}>
        <div
          onClick={() =>
            window.open(
              `https://telegram.me/share/url?url=${shareUrl}&text=${shareText}`,
              'sharer',
              options
            )
          }
          className={styles.btn}
          style={{ backgroundImage: `url(${TelegramSvg})` }}
          title="Поделиться в Telegram"
        />
        <div
          onClick={() =>
            window.open(
              `https://vk.com/share.php?url=${shareUrl}`,
              'sharer',
              options
            )
          }
          className={styles.btn}
          style={{ backgroundImage: `url(${VkSvg})` }}
          title="Поделиться в VK"
        />
        <div className={styles.qrCodeBtn} onClick={() => setQrCode(true)}>
          <BsQrCode size={32} />
        </div>
        <div
          onClick={() =>
            window.open(
              `https://connect.mail.ru/share?url=${shareUrl}&title=${shareText}`,
              'sharer',
              options
            )
          }
          className={styles.btn}
          style={{ backgroundImage: `url(${MailSvg})` }}
          title="Поделиться в @Мой мир"
        />
        <div
          onClick={() =>
            window.open(
              `https://web.whatsapp.com/send?text=${shareText}%20-%20${shareUrl}`,
              'sharer',
              options
            )
          }
          className={styles.btn}
          style={{ backgroundImage: `url(${WhatsAppSvg})` }}
          title="Поделиться в WhatsApp"
        />
      </div>
    </>
  );
}
