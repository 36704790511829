import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useEventsStore, useProfileStore } from '../../store/index';
import { useParams, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import {
  participate,
  unparticipate,
  getEventParticipants,
  checkMySubscriptionOnEvent,
} from '../../helpers/fetch';
import Avatar from '../../ui/components/avatar';
import { getUserLabel, isSameDay, leftTime } from '../../helpers/utils';
import {
  BsFillPersonLinesFill,
  BsGlobe2,
  BsFillCakeFill,
  BsCashCoin,
  BsFilePersonFill,
  BsPinMapFill,
  BsFillTrashFill,
  BsCalendar2EventFill,
  BsCalendar2CheckFill,
  BsCalendar2XFill,
} from 'react-icons/bs';

import AlertDialog from '../../ui/components/dialog';
import UserListModal from '../../ui/components/usersListModal';
import Loader from '../../ui/components/loader';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import customMapMarker from '../../assets/imgs/map_marker_default.png';
import Share from '../../ui/components/share';
import Bookmark from '../../components/bookmark';
import { categoryIcons } from '../../helpers/caterogy';

const YANDEX_API_KEY = process.env.REACT_APP_YAPI;

let participants = [];

const Event = observer(() => {
  const event = useRef({
    name: '',
    description: '',
    format: 'real',
    avatar: null,
    participant_min_age: null,
    participant_max_age: null,
    participant_min_count: null,
    participant_max_count: null,
    cost: 0,
  });
  const [owner, setOwner] = useState(false);
  const [promptDialog, setPromptDialog] = useState(null);
  const [usersModal, setUsersModal] = useState(null);
  const mapRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [isParcipiant, setIsParcipiant] = useState(false);
  const [totalParcipicants, setTotalParcipicants] = useState(0);

  const eventsStore = useEventsStore();
  const profileStore = useProfileStore();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const isVirtual = event.current.format == 'virtual';

  const ageControlSuccess = useMemo(() => {
    if (event.current) {
      if (
        event.current.participant_min_age == null ||
        event.current.participant_max_age == null
      )
        return true;
      return (
        event.current.participant_min_age <= profileStore.age &&
        profileStore.age <= event.current.participant_max_age
      );
    }
    return false;
  }, [event.current]);

  const countControlSuccess = useMemo(() => {
    if (!event.current) return false;
    if (
      event.current.participant_min_count == null ||
      event.current.participant_max_count == null
    )
      return true;
    return totalParcipicants < event.current.participant_max_count;
  }, [totalParcipicants, event.current]);

  const nowTime = moment();

  const [
    timeControlSuccess,
    timeLabel,
    timeIcon,
    timeStyle,
    startTime,
    endTime,
  ] = useMemo(() => {
    if (!event.current) return [false, '', '', 0, 0];

    const startTime = moment(event.current.start_at);
    const endTime = moment(event.current.end_at);

    let timeLabel = '';
    let timeStyle = '';
    let timeIcon = <BsCalendar2EventFill size={24} />;
    let timeControlSuccess = true;

    if (startTime < nowTime && nowTime < endTime) {
      timeLabel = 'сейчас идёт';
      timeStyle = styles.inTime;
      timeIcon = <BsCalendar2CheckFill size={24} />;
    } else if (nowTime > endTime) {
      timeLabel = 'завершено';
      timeStyle = styles.outOfTime;
      timeControlSuccess = false;
      timeIcon = <BsCalendar2XFill size={24} />;
    } else {
      timeLabel = `${leftTime(nowTime, startTime)} до начала`;
      timeStyle = styles.futureTime;
    }

    return [
      timeControlSuccess,
      timeLabel,
      timeIcon,
      timeStyle,
      startTime,
      endTime,
    ];
  }, [event.current]);

  const canParcipiate = useMemo(
    () => ageControlSuccess && countControlSuccess && timeControlSuccess,
    [ageControlSuccess, countControlSuccess, timeControlSuccess]
  );

  useEffect(() => {
    setLoading(true);

    async function fetchEvent() {
      event.current = await eventsStore.getEvent(parseInt(eventId), [
        'categories.type',
        'location.city',
        'cover.file',
        'author.profile.avatar.file',
      ]);
      if (!event.current) return;

      if (profileStore.id === event.current.author_id) setOwner(true);

      setTotalParcipicants(event.current.participants_count);

      const result = await checkMySubscriptionOnEvent(eventId);
      setIsParcipiant(result);

      setLoading(false);
    }

    fetchEvent();
  }, []);

  const fetchEventParticipants = async () => {
    setLoading(true);

    participants = [];
    let offset = 0;

    async function _getEventParticipants() {
      const result = await getEventParticipants(
        event.current.id,
        'profile.avatar.file',
        100 * offset
      );
      if (!result) {
        return;
      }

      participants.push(...result.list);
      if (participants.length < result.total_count) {
        offset++;
        await _getEventParticipants();
      }
    }

    await _getEventParticipants();
    setLoading(false);
    showEventParticipants();
  };

  const showEventParticipants = () => {
    setUsersModal({
      title: ['Участники мероприятия'],
      users: [participants],
      subscribes: false,
      acceptHandler: null,
      cancelHandler: null,
    });
  };

  const onParticipate = async () => {
    const response = await participate(event.current.id);
    if (response) {
      setIsParcipiant(true);
      setTotalParcipicants((prev) => ++prev);
    }
  };

  const onUnparticipate = async () => {
    const result = await unparticipate(event.current.id);
    if (result) {
      setIsParcipiant(false);
      setTotalParcipicants((prev) => --prev);
    }
  };

  const closeUsersModal = () => {
    setUsersModal(null);
  };

  const openUserProfile = (userId) => {
    if (window.Telegram?.WebView.initParams) {
      const profileUrl = `https://t.me/Meropriatnik_bot/meropriatnik?startapp=user_${userId}`;
      window.Telegram.WebApp.openTelegramLink(profileUrl);
      return;
    }

    if (userId === profileStore.id) navigate('/profile');
    else navigate(`/user/${userId}`);
  };

  const onCenterPoint = () => {
    mapRef.current.setCenter(
      [
        event.current.location?.coordinate_latitude,
        event.current.location?.coordinate_longitude,
      ],
      17
    );
  };

  const onEventDeletePrompt = () => {
    setPromptDialog({
      title: 'Удаление мероприятия',
      description: 'Точно хотите удалить мероприятие?',
      onAccept: () => deleteEvent(),
      onCancel: () => closePromptDialog(),
    });
  };

  const closePromptDialog = () => {
    setPromptDialog(null);
  };

  const deleteEvent = async () => {
    setLoading(true);
    const result = await eventsStore.deleteEvent(event.current.id);
    setPromptDialog(null);
    setLoading(false);
    navigate('/profile');
  };

  let parcipiantCount = 'неограничено';
  if (
    event.current.participant_min_count !== null &&
    event.current.participant_max_count !== null
  ) {
    parcipiantCount = `от ${event.current?.participant_min_count} до ${event.current?.participant_max_count}`;
  }

  let parcipiantAge = 'любой возраст';
  if (
    event.current.participant_min_age !== null &&
    event.current.participant_max_age !== null
  ) {
    parcipiantAge = `от ${event.current?.participant_min_age} до ${event.current?.participant_max_age}`;
  }

  let subscribeDescription = 'Вы не участвуете в мероприятии';

  if (isParcipiant) subscribeDescription = 'Вы участвуете в мероприятии';
  else {
    if (!timeControlSuccess)
      subscribeDescription = 'Время проведения мероприятия окончено';
    else if (!ageControlSuccess)
      subscribeDescription = 'Вы не проходите по возрасту мероприятия';
    else if (!countControlSuccess)
      subscribeDescription =
        'Мероприятие набрало максимум возможных участников';
  }

  const needYear =
    startTime.year() == nowTime.year() && endTime.year() == nowTime.year()
      ? ''
      : ' YYYY г.';

  let time = (
    <>
      <div>
        <span className={styles.label}>начало </span>
        <span>{startTime.format(`dd, Do MMM${needYear} в hh:mm`)}</span>
      </div>
      <div>
        <span className={styles.label}>конец </span>
        <span>{endTime.format(`dd, Do MMM${needYear} в hh:mm`)}</span>
      </div>
    </>
  );

  if (isSameDay(startTime, endTime)) {
    time = (
      <div>
        <span>
          {startTime.format(`dddd, Do MMM${needYear} hh:mm - `)}
          {endTime.format(`hh:mm`)}
        </span>
      </div>
    );
  }

  return (
    <>
      {loading && <Loader />}
      {usersModal && (
        <UserListModal
          owner={false}
          {...usersModal}
          opened={usersModal !== null}
          openUserProfile={openUserProfile}
          onClose={closeUsersModal}
        />
      )}
      {promptDialog && (
        <AlertDialog
          title={promptDialog.title}
          description={promptDialog.description}
          onAccept={promptDialog.onAccept}
          onCancel={promptDialog.onCancel}
        />
      )}
      <div className={styles.event}>
        <div className={styles.information}>
          {/* <div className={styles.bookmark}>
            <Bookmark eventId={event.current.id} size={24} />
          </div> */}
          {owner && (
            <div className={styles.remove} onClick={onEventDeletePrompt}>
              <BsFillTrashFill size={22} />
            </div>
          )}
          <div className={styles.shareBlock}>
            <Share eventId={eventId} shareText={event.current.name} />
          </div>
          {event.current.cover?.file?.url && (
            <div className={styles.cover}>
              <img src={event.current.cover.file.url} />
            </div>
          )}
          <div className={styles.name}>{event.current.name}</div>
          <div
            className={styles.creator}
            onClick={() =>
              openUserProfile(event.current.author?.profile.user_id)
            }
          >
            <span className={styles.label}>
              {owner ? 'Вы организатор' : 'Организатор'}
            </span>
            <div className={styles.user}>
              <div className={styles.avatar}>
                <Avatar
                  avatar={event.current.author?.profile.avatar?.file.url}
                  size={24}
                />
              </div>
              <span className={styles.userName}>
                {getUserLabel(event.current.author?.profile)}
              </span>
            </div>
          </div>
          <div className={`${styles.timeBlock} ${timeStyle}`}>
            <div className={styles.icon}>
              {timeIcon} <span>{timeLabel}</span>
            </div>
            <div className={styles.time}>{time}</div>
          </div>
          <div className={styles.description}>
            {event.current.description}
            <div className={styles.categories}>
              {event.current.categories?.map((cat) => (
                <div
                  key={cat.id}
                  className={styles.category}
                  title={cat.type.description}
                >
                  {cat.type.name}
                  <span className={styles.icon}>
                    {categoryIcons[cat.type_id - 1]}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.props}>
            <div className={styles.cost}>
              <span className={styles.icon}>
                <BsCashCoin />
              </span>
              {event.current.cost === 0 ? (
                <span className={styles.green}>бесплатно</span>
              ) : (
                `${event.current.cost} руб.`
              )}
            </div>
            <div
              className={`${styles.ages} ${
                ageControlSuccess ? '' : styles.error
              }`}
            >
              <span className={styles.icon}>
                <BsFillCakeFill />
              </span>
              <span>{parcipiantAge}</span>
              {!ageControlSuccess && (
                <span> (ваш возраст {profileStore.age})</span>
              )}
            </div>
            <div className={styles.count}>
              <span className={styles.icon}>
                <BsFillPersonLinesFill />
              </span>
              <span>{parcipiantCount}</span>
              <span
                className={styles.participants}
                onClick={owner ? fetchEventParticipants : null}
                title={owner ? 'Показать участников' : `Всего участников`}
              >
                <span className={styles.pcount}>{totalParcipicants}</span>
                <BsFilePersonFill />
                {totalParcipicants == event.current.participant_max_count && (
                  <span>(максимум)</span>
                )}
              </span>
            </div>
            {isVirtual && (
              <div className={styles.virtualAddress}>
                <span className={styles.icon}>
                  <BsGlobe2 />
                </span>
                <span>{event.current.location?.address}</span>
              </div>
            )}
          </div>
          {!isVirtual && (
            <div className={styles.address}>
              <div className={styles.title}>
                <span>
                  {event.current.location?.city.type_short_name}{' '}
                  {event.current.location?.city.name}
                  {'. '}
                </span>
                <span>{event.current.location?.address}</span>
              </div>
              <div className={styles.map}>
                <YMaps query={{ apikey: YANDEX_API_KEY }}>
                  <Map
                    width="100%"
                    height="100%"
                    state={{
                      center: [
                        event.current.location?.coordinate_latitude,
                        event.current.location?.coordinate_longitude,
                      ],
                      zoom: 17,
                      controls: ['zoomControl'],
                    }}
                    modules={['control.ZoomControl']}
                    instanceRef={(ref) => (mapRef.current = ref)}
                  >
                    <Placemark
                      geometry={[
                        event.current.location?.coordinate_latitude,
                        event.current.location?.coordinate_longitude,
                      ]}
                      options={{
                        iconLayout: 'default#image',
                        iconImageHref: customMapMarker,
                        iconImageSize: [32, 32],
                      }}
                    />
                  </Map>
                </YMaps>
                <div
                  className={styles.navigateLink}
                  title="Вернуться к точке"
                  onClick={onCenterPoint}
                >
                  <BsPinMapFill />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.subscribeSection}>
          <span className={styles.description}>{subscribeDescription}</span>
          <SimpleButton
            classes={styles.subscribeBtn}
            disabled={!canParcipiate && !isParcipiant}
            text={isParcipiant ? 'ОТКАЗАТЬСЯ' : 'УЧАСТВОВАТЬ'}
            onClick={() => (isParcipiant ? onUnparticipate() : onParticipate())}
          />
        </div>
      </div>
    </>
  );
});

export default Event;
